import axios from 'axios';

const baseURLConfig = () => {
    try {
        const whiteList = [2, 3];
        const {pipe} = JSON.parse(window.localStorage.getItem('auth'));
        return whiteList.includes(pipe) ? 'https://thor.valsports.net' : 'https://api.valsports.net';
    } catch(e) {
        return 'https://api.valsports.net';
    }
}

// const baseURL = () => {
//     return `https://api.${window.location.host}`;
// }

export const api = axios.create({
    baseURL: baseURLConfig(),
    timeout: 1800000
});

api.interceptors.request.use((config) => {
    const token = localStorage.getItem('x-token');
    config.headers['X-Web-Token'] = true;
    config.headers['X-Access-Token'] = token ? token : undefined;
    return config;
}, (error) => {
	return Promise.reject(error);
});

api.interceptors.response.use((response) => {
	return response;
}, (error) => {
	return Promise.reject(error.response);
});